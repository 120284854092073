const bootstrap = require('bootstrap');







// Modal initialization with static backdrop
var inquiryModal = new bootstrap.Modal(document.getElementById('inquiryModal'), {
    backdrop: 'static', // This makes the backdrop static
    keyboard: false     // Prevent modal from closing when Esc is pressed
});

// Open the modal when the button is clicked
document.getElementById('openModalButton').addEventListener('click', function () {

    // Reset the form fields
    document.getElementById('inquiry_form').reset();
    
    // Show the first step and hide the other steps
    document.getElementById('step1').style.display = 'block';
    document.getElementById('step2').style.display = 'none';
    document.getElementById('step3').style.display = 'none';
    document.getElementById('step3a').style.display = 'none';
    document.getElementById('step4').style.display = 'none';
    document.getElementById('step5').style.display = 'none';
    document.getElementById('step6').style.display = 'none';

    // select first button by default
    document.getElementById('type').value = 'on-site';
    typeButtons.onSite.classList.add('btn-primary');
    typeButtons.onSite.classList.remove('btn-outline-primary');
    typeButtons.online.classList.add('btn-outline-primary');
    typeButtons.online.classList.remove('btn-primary');

    // reset address field visibility
    addressField.style.display = 'block';  

    // reset file upload 
    const fileInput = document.getElementById('attachment');
    fileInput.value = ''; // Clear the input
    const errorFeedback = document.getElementById('fileSizeError');
    errorFeedback.style.display = 'none';

    inquiryModal.show();
}); 





// NextStep2  button
function triggerNextStep2Button() {
    document.getElementById("nextStep2").click();
}

// Add event listeners to both input boxes
document.getElementById("name").addEventListener("keydown", function(event) {
    if (event.key === "Enter") {
        event.preventDefault();
        triggerNextStep2Button();
    }
});
document.getElementById("phone").addEventListener("keydown", function(event) {
    if (event.key === "Enter") {
        event.preventDefault();
        triggerNextStep2Button();
    }
});
document.getElementById("email").addEventListener("keydown", function(event) {
    if (event.key === "Enter") {
        event.preventDefault();
        triggerNextStep2Button();
    }
});
document.getElementById("address").addEventListener("keydown", function(event) {
    if (event.key === "Enter") {
        event.preventDefault();
        triggerNextStep2Button();
    }
});

// NextStep3  button
function triggerNextStep3Button() {
    document.getElementById("nextStep3").click();
}

// // Add event listeners to both input boxes
// document.getElementById("request_time").addEventListener("keydown", function(event) {
//     if (event.key === "Enter") {
//         event.preventDefault();
//         triggerNextStep3Button();
//     }
// });
document.getElementById("request_date").addEventListener("keydown", function(event) {
    if (event.key === "Enter") {
        event.preventDefault();
        triggerNextStep3Button();
    }
});







// submit button
document.getElementById('inquiry_form').addEventListener('submit', function (e) {
    e.preventDefault(); // Prevent the default form submission

    try {
        const form = document.getElementById('inquiry_form');
        const formData = new FormData(form);

        fetch('/console/api/inquiries/add_web', {
            method: 'POST',
            body: formData, 
        })
        .then(response => response.json())
        .then(data => {
            //console.log('Success:', data);
            document.getElementById('step4').style.display = 'none';
            document.getElementById('step5').style.display = 'block';
        })
        .catch(error => {
            //console.error('Error:', error);
            document.getElementById('step4').style.display = 'none';
            document.getElementById('step6').style.display = 'block';
        });

    } catch (error) {
        document.getElementById('step4').style.display = 'none';
        document.getElementById('step6').style.display = 'block';
    }
});


 




// Navigation between steps
document.getElementById('nextStep1').addEventListener('click', function () {
    document.getElementById('step1').style.display = 'none';
    document.getElementById('step2').style.display = 'block';
});

document.getElementById('backStep2').addEventListener('click', function () {
    document.getElementById('step2').style.display = 'none';
    document.getElementById('step1').style.display = 'block';
});

document.getElementById('nextStep2').addEventListener('click', function () {
        
       var nameField = document.getElementById('name');
       var phoneField = document.getElementById('phone');
       var emailField = document.getElementById('email');
       var addressField = document.getElementById('address');
       var addressBlock = document.getElementById('addressField');
       var emptyFeedback = document.getElementById('emptyFeedback');
       var patternFeedback = document.getElementById('patternFeedback');

        var isValid = true;

        // Check if the name field is valid
        if (nameField.value.trim() === "") {
            nameField.classList.add('is-invalid');
            isValid = false;
        } else {
            nameField.classList.remove('is-invalid');
            //nameField.classList.add('is-valid');
        }
        // Check if the phone field is valid
        emptyFeedback.style.display = 'none';
        patternFeedback.style.display = 'none';

        if (phoneField.value.trim() === "") {
            phoneField.classList.add('is-invalid');
            emptyFeedback.style.display = 'block';
            isValid = false;
        } else {
            phoneField.classList.remove('is-invalid');
            //nameField.classList.add('is-valid');
        }
        // Regular expression for the pattern xxx-xxx-xxxx
        const phonePattern = /^\d{3}-\d{3}-\d{4}$/;

        if (phoneField.value.trim() != "" && !phonePattern.test(phoneField.value.trim())) {
            phoneField.classList.add('is-invalid');
            patternFeedback.style.display = 'block';
            isValid = false;
        } else if (phoneField.value.trim() === "") {
            // phoneField.classList.add('is-invalid');
        } else {
            phoneField.classList.remove('is-invalid');
            //phoneField.classList.add('is-valid');  
        }
        // Check if the email field is valid
        if (emailField.value.trim() === "" || !emailField.checkValidity()) {
            emailField.classList.add('is-invalid');
            isValid = false;
        } else {
            emailField.classList.remove('is-invalid');
            //nameField.classList.add('is-valid');
        }
        // Check if the address field is valid
        if (addressBlock.style.display === 'block')
        {
            if (addressField.value.trim() === "" ) {
                addressField.classList.add('is-invalid');
                isValid = false;
            } else {
                addressField.classList.remove('is-invalid');
                //nameField.classList.add('is-valid');
            }
        }

        // If the form is valid, proceed to the next step
        if (isValid) {
            document.getElementById('step2').style.display = 'none';
            document.getElementById('step3').style.display = 'block';
        }
});

document.getElementById('backStep3').addEventListener('click', function () {
    document.getElementById('step3').style.display = 'none';
    document.getElementById('step2').style.display = 'block';
});

document.getElementById('nextStep3').addEventListener('click', function () {

    let valid = true;

    // Get the date and time values
    const requestDate = new Date(document.getElementById('request_date').value);
    //const requestTime = document.getElementById('request_time').value;

    // Check if the date is a weekday (Monday to Friday)
    const day = requestDate.getDay(); // 6 is Sunday, 5 is Saturday
    if (day === 6) { // If it's Sunday (6) 
        valid = false;
        document.getElementById('request_date').classList.add('is-invalid');
        document.getElementById('date_feedback').style.display = 'block';
    } else {
        document.getElementById('request_date').classList.remove('is-invalid');
        document.getElementById('date_feedback').style.display = 'none';
    }

    // // Check if the time is between 9 AM and 5 PM
    // const [hours, minutes] = requestTime.split(':');
    // const requestHour = parseInt(hours, 10);
    // if (requestHour < 9 || requestHour >= 17) { // Time not within 9 AM - 5 PM
    //     valid = false;
    //     document.getElementById('request_time').classList.add('is-invalid');
    //     document.getElementById('time_feedback').style.display = 'block';
    // } else {
    //     document.getElementById('request_time').classList.remove('is-invalid');
    //     document.getElementById('time_feedback').style.display = 'none';
    // }

    if (valid) {
        //document.getElementById('step3').style.display = 'none';
        //document.getElementById('step4').style.display = 'block';
        document.getElementById('step3').style.display = 'none';
        document.getElementById('step3a').style.display = 'block';
    }
});

document.getElementById('backStep3a').addEventListener('click', function () {
    document.getElementById('step3a').style.display = 'none';
    document.getElementById('step3').style.display = 'block';
});

document.getElementById('nextStep3a').addEventListener('click', function () {
    document.getElementById('step3a').style.display = 'none';
    document.getElementById('step4').style.display = 'block';
});

document.getElementById('backStep4').addEventListener('click', function () {
    document.getElementById('step4').style.display = 'none';
    document.getElementById('step3a').style.display = 'block';
});

// document.getElementById('nextStep5').addEventListener('click', function () {
//     document.getElementById('step4').style.display = 'none';
//     document.getElementById('step5').style.display = 'block';
// });

// document.getElementById('nextStep5a').addEventListener('click', function () {
//     document.getElementById('step4').style.display = 'none';
//     document.getElementById('step5').style.display = 'block';
// });

// Handle type selection and toggle email field visibility
const typeButtons = {
    onSite: document.getElementById('typeOnSite'),
    online: document.getElementById('typeOnline')
};

const addressField = document.getElementById('addressField');
addressField.style.display = 'block'; // Default visibility

typeButtons.onSite.addEventListener('click', function () {
    document.getElementById('type').value = 'on-site';
    typeButtons.onSite.classList.add('btn-primary');
    typeButtons.onSite.classList.remove('btn-outline-primary');
    typeButtons.online.classList.add('btn-outline-primary');
    typeButtons.online.classList.remove('btn-primary');

    // Show address field if on-site is selected
    addressField.style.display = 'block';
    document.getElementById("address").setAttribute("required", "required");
});

typeButtons.online.addEventListener('click', function () {
    document.getElementById('type').value = 'online';
    typeButtons.online.classList.add('btn-primary');
    typeButtons.online.classList.remove('btn-outline-primary');
    typeButtons.onSite.classList.add('btn-outline-primary');
    typeButtons.onSite.classList.remove('btn-primary');

    // Hide address field if online is selected
    addressField.style.display = 'none';
    document.getElementById("address").removeAttribute("required");
});

// attachment button
document.getElementById("uploadButton").addEventListener("click", function() {
    document.getElementById("attachment").click();
});

document.getElementById('attachment').addEventListener('change', function() {
    const fileInput = this;
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    const file = fileInput.files[0];
    const errorFeedback = document.getElementById('fileSizeError');
    const successFeedback = document.getElementById('fileUploadSuccess');

    if (file && file.size > maxSize) {
        errorFeedback.style.display = 'block';
        fileInput.value = ''; // Clear the input
    } else {
        errorFeedback.style.display = 'none';
        successFeedback.textContent = `${file.name} has been attached`;  
        successFeedback.style.display = 'block';    
 
    }
});


/**/
/* FOR PROJECTS PAGE */
/**/
// When a dropdown item is clicked
$('.dropdown-item').on('click', function(e) {
    e.preventDefault();
    const divId = $(this).data('div');
    const selectedText = $(this).text();
    
    // Hide all divs and show the selected one
    $('.div-content').addClass('d-none');
    $('#div' + divId).removeClass('d-none');
    
    // Update dropdown button text with the selected option
    $('#divDropdown').text('' + selectedText);
});